import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIInvioPresenze {


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async getFilterData(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("EmtSelectCompanyPeriodApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async stepValidate(id_azienda, anno, mese, ore_straord, user) {

    let formData = new FormData();

    formData.append("v_id_azienda", id_azienda);
    formData.append("v_anno", anno);
    formData.append("v_mese", mese);
    formData.append("v_ore_straord", ore_straord);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtValidateCompanyPeriodApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async stepInit(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepInitApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async stepLUL(anno, mese, azienda, send_overtime, user) {

    let formData = new FormData();

    formData.append("anno_sel", anno);
    formData.append("mese_sel", mese);
    formData.append("azienda_sel", azienda);
    formData.append("send_overtime", send_overtime);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepLULApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async stepPdf(anno, mese, azienda, send_overtime, user) {

    let formData = new FormData();

    formData.append("anno_sel", anno);
    formData.append("mese_sel", mese);
    formData.append("azienda_sel", azienda);
    formData.append("send_overtime", send_overtime);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepPdfApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async stepRile01(anno, mese, azienda, send_overtime, user) {

    let formData = new FormData();

    formData.append("anno_sel", anno);
    formData.append("mese_sel", mese);
    formData.append("azienda_sel", azienda);
    formData.append("send_overtime", send_overtime);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepRile01Api"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async stepRile02(anno, mese, azienda, send_overtime, user) {

    let formData = new FormData();

    formData.append("anno_sel", anno);
    formData.append("mese_sel", mese);
    formData.append("azienda_sel", azienda);
    formData.append("send_overtime", send_overtime);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepRile02Api"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async stepAnn(anno, mese, azienda, send_overtime, user) {

    let formData = new FormData();

    formData.append("anno_sel", anno);
    formData.append("mese_sel", mese);
    formData.append("azienda_sel", azienda);
    formData.append("send_overtime", send_overtime);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepAnnotationApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async stepMail(anno, mese, azienda, send_overtime, user) {

    let formData = new FormData();

    formData.append("anno_sel", anno);
    formData.append("mese_sel", mese);
    formData.append("azienda_sel", azienda);
    formData.append("send_overtime", send_overtime);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtElabStepSendMailApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIInvioPresenze({
  url: "http://emtool.local/public/api/smartbook/"
})
